<template>
  <v-app>
    <div
      class="page"
      :style="
        `background:#fff;`
      "
    >
      <div class="error__content">
        <v-container>
          <div v-if="content === false">
            <h1 class="error__title">
              <svg
                id="error"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="400px"
                height="400px"
                viewBox="0 0 400 400"
                enable-background="new 0 0 400 400"
                xml:space="preserve"
              >
                <g id="svg-mask">
                  <path
                    fill="#f6f5f4"
                    d="M6.789,4.774v325.322h51.02c15.366,15.557,69.323,27.047,133.529,27.047
				c64.207,0,118.164-11.49,133.529-27.047h67.938V4.774H6.789z"
                  />
                </g>
                <g id="svg-hole">
                  <g>
                    <path
                      fill="#C0C0C0"
                      d="M324.867,330.098c2.863-2.897,4.389-5.938,4.389-9.072c0-19.946-61.748-36.116-137.918-36.116
					S53.42,301.078,53.42,321.025c0,3.133,1.524,6.174,4.389,9.07c15.366-15.556,69.323-27.046,133.529-27.046
					C255.545,303.051,309.502,314.541,324.867,330.098z"
                    />
                    <path
                      fill="#333333"
                      d="M324.867,330.098c-15.365-15.557-69.322-27.047-133.529-27.047c-64.206,0-118.163,11.49-133.529,27.047
					c15.366,15.557,69.323,27.045,133.529,27.045C255.545,357.143,309.502,345.652,324.867,330.098z"
                    />
                  </g>
                </g>
                <g id="svg-hand_1_">
                  <g id="svg-hand">
                    <path
                      fill="#F6AF74"
                      stroke="#F6B075"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M224.002,639.541
			c-0.989-4.156-2.063-8.734-3.207-13.664c-0.425-1.831-0.858-3.705-1.302-5.631c-0.957,1.461-2.423,3.426-4.395,5.339
			c-3.47,3.367-8.512,6.571-15.142,6.571c-0.448,0-0.903-0.014-1.364-0.043c-5.405-0.354-11.179-1.168-17.174-2.422
			c-0.208,6.727-0.282,15.629,0.449,21.48c0.872,6.979,3.285,39.989,3.925,48.082c2.282,0.029,4.577,0.046,6.888,0.046
			c14.876,0,31.888-0.616,45.312-1.758C235.236,687.629,226.856,651.527,224.002,639.541z"
                    />
                    <path
                      fill="#EEA36C"
                      d="M225.31,639.23c-0.963-4.043-2.007-8.49-3.116-13.271c-0.551-2.371-1.115-4.821-1.697-7.353l0.002-0.004
			l-0.06-0.258c-2.471-10.757-4.307-18.978-4.307-18.979l-0.202-0.904l-35.377,20.811l-0.023,0.356
			c-0.002,0.039-0.253,3.972-0.429,9.216l-0.019,0.562l0.004,0.001c-0.216,6.8-0.3,15.92,0.451,21.93
			c0.863,6.921,3.27,39.81,3.907,47.896c0.227,0.004,0.45,0.009,0.677,0.012c0.225,0.003,0.448,0.006,0.674,0.009
			c-0.64-8.094-3.053-41.103-3.925-48.082c-0.73-5.853-0.657-14.755-0.448-21.479c0.54,0.113,1.066,0.203,1.603,0.309
			c0.89,0.175,1.78,0.355,2.658,0.512c0.566,0.102,1.121,0.182,1.684,0.273c0.929,0.152,1.859,0.311,2.774,0.439
			c0.448,0.062,0.883,0.107,1.328,0.166c1.007,0.131,2.017,0.267,3.005,0.369c0.035,0.004,0.068,0.006,0.104,0.01
			c1.357,0.141,2.701,0.26,4.019,0.346c0.46,0.029,0.915,0.045,1.363,0.045c0.001,0,0,0,0.001,0c0.819,0,1.611-0.055,2.383-0.146
			c0.036-0.004,0.071-0.01,0.107-0.015c0.341-0.043,0.677-0.093,1.008-0.151c0.057-0.011,0.111-0.021,0.169-0.031
			c0.312-0.06,0.618-0.123,0.921-0.195c0.061-0.014,0.119-0.028,0.179-0.043c0.304-0.075,0.604-0.156,0.897-0.244
			c0.049-0.016,0.098-0.027,0.146-0.043c0.315-0.098,0.627-0.2,0.934-0.311c0.021-0.008,0.045-0.017,0.066-0.023
			c6.483-2.363,10.721-7.646,12.727-10.71c0.442,1.924,0.877,3.798,1.302,5.629c1.144,4.931,2.219,9.509,3.207,13.664
			c2.854,11.985,11.232,48.089,13.99,58.001c0.227-0.02,0.454-0.039,0.681-0.057c0.228-0.021,0.454-0.041,0.683-0.062
			C236.605,687.54,228.182,651.294,225.31,639.23z"
                    />
                    <path
                      fill="#BA5B3C"
                      d="M191.569,372.173c-0.236-5.108-4.036-9.083-8.484-8.875l-5.034,0.233
			c-4.448,0.209-7.861,4.519-7.623,9.628l0.486,10.153l21.119-1.482L191.569,372.173z"
                    />
                    <polygon
                      fill="#eeeeee"
                      points="292.153,374.793 192.031,381.83 170.911,383.312 69.614,390.432 75.595,500.578 176.198,493.645
			197.32,492.189 302.378,484.949"
                    />
                    <path
                      fill="#F6AF74"
                      d="M221.744,563.232c-2.017-6.271-6.721-18.145-11.199-24.64c-4.48-6.495-6.72-25.086-10.545-29.343
			c-0.626-0.695-1.279-1.161-1.934-1.458l1.834,38.283l4.817-0.144c4.451-0.131,8.164,3.287,8.293,7.633l0.084,2.842
			c0.128,4.348-3.376,7.978-7.827,8.109l-4.478,0.131l0.042,0.875l9.457-0.278c4.905-0.146,8.999,3.593,9.14,8.35l0.092,3.109
			c0.142,4.758-3.724,8.729-8.631,8.875l-9.084,0.271l0.053,1.092l4.416-0.131c4.26-0.127,7.815,3.297,7.943,7.644l0.084,2.841
			c0.129,4.348-3.22,7.973-7.479,8.1l-4.074,0.121l0.041,0.875l1.812-0.055c3.77-0.111,6.897,2.328,6.989,5.447l0.061,2.039
			c0.093,3.119-2.888,5.74-6.654,5.851l-1.568,0.046l-21.161,0.625l-11.761,0.35c-0.796,0.022-1.562-0.073-2.277-0.26
			c0,0,5.94,1.797,14.196,3.197c3.627,0.615,7.698,1.154,11.913,1.43c3.458,0.228,6.55-0.447,9.266-1.662
			c8.132-3.639,12.881-12.129,13.906-15.838c1.367-4.951-0.695-9.387-0.695-9.387s3.136-11.646,4.928-17.246
			S223.76,569.503,221.744,563.232z"
                    />
                    <path
                      fill="#EEA36C"
                      d="M222.384,563.025c-2.045-6.361-6.737-18.219-11.286-24.814c-2.186-3.169-3.844-9.385-5.447-15.396
			c-1.613-6.055-3.141-11.775-5.149-14.012c-0.743-0.826-1.57-1.408-2.469-1.75l0.035,0.74l0.037,0.777
			c0.494,0.273,0.962,0.646,1.396,1.13c1.794,1.997,3.349,7.825,4.852,13.46c1.634,6.121,3.321,12.453,5.641,15.812
			c4.435,6.43,9.17,18.418,11.112,24.463c1.864,5.803,1.864,11.457,0,17.285c-1.776,5.551-4.904,17.159-4.938,17.276l-0.062,0.235
			l0.103,0.222c0.02,0.042,1.938,4.284,0.658,8.924c-1.302,4.703-9.116,17.882-22.48,17.009c-9.426-0.615-18.22-2.576-22.754-3.73
			l-1.122,0.033c-0.796,0.022-1.561-0.072-2.277-0.26c-0.22-0.058-0.434-0.128-0.644-0.201l-0.146,0.664l0.596,0.18
			c0.123,0.039,12.531,3.758,26.26,4.656c0.462,0.029,0.915,0.045,1.364,0.045c13.363,0,21.169-13.223,22.499-18.037
			c1.254-4.539-0.218-8.608-0.634-9.616c0.446-1.649,3.229-11.897,4.857-16.991C224.337,575.025,224.337,569.104,222.384,563.025z"
                    />
                    <polygon
                      fill="#BA5B3C"
                      points="198.105,508.57 198.068,507.793 198.031,507.053 197.32,492.189 176.198,493.645 178.74,546.7
			183.574,546.559 184.263,546.537 184.95,546.518 199.902,546.076 		"
                    />
                    <path
                      fill="#F6AF74"
                      d="M175.586,586.623l0.691-0.021l17.121-0.506l8.407-0.248l9.084-0.268c4.907-0.145,8.771-4.118,8.631-8.875
			l-0.092-3.109c-0.141-4.758-4.233-8.496-9.14-8.352l-29.557,0.875l-0.69,0.02l-0.37,0.012l-0.322,0.011l-9.469,0.278l-0.025-0.875
			l9.688-0.285l0.089-0.004l0.604-0.018l0.691-0.02l19.865-0.588l4.478-0.133c4.451-0.131,7.956-3.763,7.826-8.107l-0.084-2.842
			c-0.128-4.348-3.841-7.764-8.292-7.633l-4.817,0.143l-14.95,0.441l-0.688,0.02l-0.688,0.021l-4.833,0.143l-14.771,0.436
			c-4.452,0.131-7.956,3.764-7.827,8.109l0.084,2.842c0.119,4.031,3.323,7.258,7.339,7.602l0.033,1.105
			c-4.032,0.918-6.982,4.482-6.859,8.648l0.093,3.111c0.122,4.166,3.316,8.862,7.396,9.541l0.019,0.604
			c-2.696,1.291-4.527,4.125-4.434,7.357l0.084,2.842c0.129,4.348,3.686,7.77,7.943,7.645l0.965-0.029l0.029,0.979
			c-3.142,0.59-5.459,2.952-5.377,5.709l0.06,2.039c0.067,2.258,1.729,4.151,4.068,4.985c0.209,0.074,0.423,0.146,0.644,0.203
			c0.717,0.187,1.48,0.282,2.277,0.26l1.121-0.032l33.368-0.987c3.768-0.109,6.748-2.73,6.655-5.852l-0.061-2.039
			c-0.093-3.118-3.222-5.559-6.989-5.446l-29.223,0.862l-0.026-0.875l31.471-0.93c4.259-0.125,7.605-3.752,7.478-8.1l-0.084-2.841
			c-0.127-4.347-3.684-7.769-7.942-7.644l-30.236,0.893l-0.692,0.021l-0.692,0.021l-4.14,0.122l-0.032-1.092l4.411-0.131
			L175.586,586.623z"
                    />
                    <path
                      fill="#EEA36C"
                      d="M170.26,621.367c-4.056,0-7.312-2.682-7.413-6.104l-0.06-2.039c-0.078-2.625,1.768-4.986,4.54-6.009
			c-4.433-0.151-7.966-3.739-8.101-8.295l-0.084-2.842c-0.091-3.093,1.461-5.964,4.016-7.546c-4.087-1.262-6.88-5.963-6.997-9.957
			l-0.094-3.11c-0.124-4.213,2.718-7.985,6.827-9.18c-4.104-0.638-7.218-4.054-7.339-8.179l-0.084-2.842
			c-0.14-4.711,3.664-8.658,8.479-8.801l40.748-1.203c4.875-0.15,8.844,3.549,8.983,8.285l0.084,2.842
			c0.067,2.283-0.779,4.457-2.385,6.121c-0.92,0.953-2.026,1.672-3.242,2.125l2.13-0.064c5.335-0.146,9.68,3.857,9.831,9.004
			l0.092,3.109c0.073,2.482-0.854,4.848-2.611,6.654c-1.753,1.803-4.122,2.836-6.671,2.912l-2.606,0.076
			c3.698,0.812,6.468,4.082,6.587,8.105l0.084,2.842c0.068,2.279-0.739,4.447-2.271,6.106c-1.409,1.524-3.276,2.453-5.307,2.647
			c2.795,0.85,4.789,3.064,4.868,5.73l0.062,2.039c0.05,1.713-0.699,3.338-2.109,4.578c-1.374,1.208-3.221,1.906-5.199,1.964
			l-34.49,1.021C170.438,621.365,170.35,621.367,170.26,621.367z M169.459,605.824l0.064,2.226l-0.563,0.104
			c-2.869,0.539-4.899,2.654-4.83,5.029l0.061,2.039c0.081,2.754,2.873,4.918,6.297,4.797l34.491-1.021
			c1.665-0.049,3.211-0.629,4.353-1.631c1.104-0.97,1.69-2.224,1.651-3.529l-0.061-2.038c-0.079-2.691-2.746-4.8-6.07-4.8
			l-0.228,0.004l-29.896,0.884l-0.064-2.22l32.141-0.948c1.877-0.056,3.622-0.854,4.91-2.252c1.293-1.398,1.974-3.23,1.916-5.156
			l-0.084-2.842c-0.115-3.923-3.209-6.994-7.044-6.994l-0.207,0.004l-36.434,1.076l-0.071-2.437l41.08-1.216
			c2.197-0.063,4.238-0.952,5.747-2.504c1.501-1.545,2.294-3.562,2.23-5.68l-0.092-3.109c-0.13-4.4-3.847-7.832-8.447-7.696
			l-41.081,1.213l-0.064-2.217l36.084-1.067c1.977-0.058,3.812-0.863,5.167-2.269c1.352-1.4,2.063-3.229,2.008-5.147l-0.084-2.843
			c-0.117-3.989-3.464-7.125-7.603-6.979l-40.747,1.203c-4.073,0.121-7.293,3.447-7.176,7.418l0.084,2.842
			c0.107,3.645,2.999,6.633,6.727,6.951l0.597,0.051l0.067,2.258l-0.539,0.123c-3.783,0.859-6.448,4.215-6.338,7.975l0.093,3.109
			c0.107,3.689,2.941,8.252,6.835,8.898l0.545,0.09l0.046,1.595l-0.394,0.188c-2.545,1.217-4.136,3.859-4.052,6.731l0.084,2.842
			c0.116,3.923,3.211,6.994,7.046,6.994L169.459,605.824z"
                    />
                    <g>
                      <path
                        d="M146.602,428.122c0.367,5.899-0.837,10.632-3.609,14.196c-2.773,3.563-6.817,5.511-12.129,5.841
				c-5.43,0.338-9.722-1.062-12.878-4.198c-3.155-3.137-4.923-7.73-5.299-13.783c-0.373-6,0.816-10.744,3.57-14.23 c2.753-3.486,6.853-5.399,12.299-5.737c5.295-0.33,9.534,1.095,12.719,4.271C144.458,417.659,146.235,422.205,146.602,428.122z
				M117.228,429.947c0.312,4.992,1.609,8.712,3.896,11.16c2.287,2.449,5.456,3.547,9.506,3.295c4.085-0.254,7.09-1.732,9.015-4.434
				c1.924-2.701,2.731-6.557,2.42-11.566c-0.309-4.959-1.581-8.654-3.814-11.09c-2.235-2.434-5.388-3.525-9.454-3.271
				c-4.085,0.254-7.111,1.736-9.076,4.449C117.751,421.204,116.922,425.021,117.228,429.947z"
                      />
                      <path
                        d="M157.556,418.253l1.114,17.929c0.14,2.252,0.757,3.9,1.852,4.946c1.095,1.045,2.733,1.5,4.919,1.365
				c2.892-0.181,4.956-1.101,6.194-2.765c1.238-1.662,1.745-4.285,1.523-7.865l-0.902-14.523l4.186-0.26l1.718,27.634l-3.453,0.214
				l-0.836-3.668l-0.228,0.014c-0.771,1.416-1.896,2.531-3.373,3.349s-3.188,1.287-5.14,1.407c-3.361,0.209-5.928-0.432-7.699-1.926
				c-1.771-1.492-2.768-3.994-2.985-7.508l-1.124-18.078L157.556,418.253z"
                      />
                      <path
                        d="M199.924,443.867c-1.799,0.111-3.463-0.118-4.991-0.689c-1.528-0.572-2.841-1.515-3.935-2.83l-0.304,0.018
				c0.303,1.601,0.498,3.125,0.589,4.57l0.707,11.371l-4.187,0.26l-2.488-40.039l3.403-0.211l0.815,3.746l0.2-0.013
				c0.981-1.579,2.166-2.75,3.555-3.511c1.387-0.762,3.005-1.2,4.854-1.314c3.666-0.227,6.57,0.85,8.719,3.23
				c2.146,2.381,3.361,5.832,3.645,10.354c0.28,4.539-0.513,8.127-2.382,10.767C206.254,442.212,203.522,443.645,199.924,443.867z
				M197.76,418.816c-2.824,0.176-4.818,1.084-5.981,2.727c-1.162,1.643-1.655,4.169-1.478,7.583l0.059,0.935
				c0.24,3.883,1.062,6.621,2.459,8.213c1.398,1.592,3.526,2.297,6.385,2.121c2.387-0.148,4.196-1.231,5.43-3.248
				c1.231-2.018,1.743-4.724,1.534-8.119c-0.216-3.446-1.057-6.049-2.522-7.805C202.175,419.467,200.214,418.665,197.76,418.816z"
                      />
                      <path
                        d="M236.034,433.549c0.158,2.572-0.676,4.614-2.505,6.129c-1.829,1.516-4.477,2.379-7.938,2.594
				c-3.665,0.229-6.559-0.174-8.681-1.207l-0.242-3.883c1.369,0.59,2.828,1.031,4.374,1.323c1.544,0.293,3.021,0.394,4.437,0.306
				c2.186-0.136,3.843-0.588,4.977-1.359c1.135-0.77,1.655-1.871,1.566-3.299c-0.066-1.076-0.591-1.967-1.569-2.674
				c-0.981-0.707-2.856-1.498-5.63-2.371c-2.63-0.799-4.511-1.521-5.64-2.168s-1.983-1.408-2.571-2.283
				c-0.586-0.875-0.918-1.942-0.995-3.203c-0.141-2.252,0.666-4.088,2.417-5.504s4.225-2.225,7.417-2.422
				c2.976-0.186,5.921,0.238,8.837,1.272l-1.275,3.496c-2.846-0.97-5.393-1.386-7.646-1.246c-1.984,0.124-3.46,0.527-4.432,1.212
				c-0.969,0.684-1.42,1.573-1.354,2.666c0.046,0.739,0.273,1.357,0.686,1.854c0.411,0.498,1.05,0.957,1.92,1.375
				c0.871,0.419,2.525,0.998,4.97,1.742c3.352,0.99,5.643,2.053,6.867,3.191C235.25,430.229,235.92,431.717,236.034,433.549z"
                      />
                      <path
                        d="M255.951,437.195c-0.143-2.285,0.797-3.491,2.813-3.616c0.976-0.062,1.745,0.187,2.312,0.741
				c0.564,0.557,0.881,1.389,0.951,2.498c0.067,1.076-0.149,1.929-0.65,2.56s-1.229,0.976-2.188,1.035
				c-0.874,0.056-1.611-0.166-2.218-0.66C256.368,439.258,256.028,438.406,255.951,437.195z M259.873,429.436l-2.646,0.164
				l-2.947-26.621l5.222-0.324L259.873,429.436z"
                      />
                    </g>
                    <!-- <g>
                      <path
                        fill="#666666"
                        d="M153.228,470.893l-5.277,0.26l0.24,4.896l5.882-0.29l0.072,1.471l-7.635,0.375l-0.669-13.578l7.333-0.361
				l0.072,1.471l-5.581,0.275l0.212,4.291l5.277-0.26L153.228,470.893z"
                      />
                      <path
                        fill="#666666"
                        d="M156.035,470.412c-0.057-1.146-0.125-2.135-0.23-3.038l1.551-0.077l0.155,1.911l0.081-0.004
				c0.377-1.331,1.404-2.209,2.593-2.269c0.201-0.01,0.344,0.004,0.506,0.037l0.083,1.672c-0.184-0.031-0.364-0.043-0.605-0.031
				c-1.249,0.061-2.089,1.052-2.267,2.394c-0.027,0.244-0.055,0.527-0.04,0.83l0.257,5.198l-1.754,0.086L156.035,470.412z"
                      />
                      <path
                        fill="#666666"
                        d="M162.707,470.083c-0.058-1.147-0.126-2.136-0.23-3.038l1.551-0.076l0.154,1.911l0.081-0.005
				c0.378-1.331,1.405-2.209,2.595-2.268c0.201-0.01,0.344,0.002,0.506,0.035l0.083,1.672c-0.185-0.031-0.364-0.043-0.605-0.03
				c-1.249,0.062-2.089,1.052-2.266,2.394c-0.028,0.245-0.056,0.527-0.04,0.83l0.256,5.197l-1.753,0.086L162.707,470.083z"
                      />
                      <path
                        fill="#666666"
                        d="M170.19,471.875c0.159,2.395,1.737,3.307,3.513,3.219c1.269-0.062,2.022-0.322,2.674-0.637l0.363,1.255
				c-0.609,0.313-1.66,0.688-3.213,0.765c-3.002,0.148-4.893-1.738-5.037-4.68c-0.146-2.941,1.475-5.342,4.313-5.48
				c3.183-0.157,4.168,2.601,4.256,4.394c0.02,0.362-0.009,0.647-0.021,0.829L170.19,471.875z M175.324,470.35
				c-0.036-1.129-0.605-2.857-2.602-2.759c-1.792,0.089-2.497,1.778-2.577,3.015L175.324,470.35z"
                      />
                      <path
                        fill="#666666"
                        d="M187.552,472.918c0.051,1.008,0.113,1.895,0.212,2.654l-1.571,0.078l-0.18-1.586l-0.041,0.002
				c-0.424,0.81-1.4,1.887-3.134,1.973c-1.529,0.074-3.404-0.68-3.573-4.105l-0.281-5.699l1.773-0.088l0.267,5.398
				c0.092,1.854,0.718,3.075,2.328,2.996c1.188-0.059,1.975-0.926,2.257-1.727c0.088-0.268,0.134-0.592,0.115-0.914l-0.294-5.983
				l1.773-0.087L187.552,472.918z"
                      />
                      <path
                        fill="#666666"
                        d="M190.3,468.725c-0.057-1.148-0.125-2.136-0.23-3.039l1.552-0.076l0.154,1.91l0.08-0.004
				c0.378-1.33,1.405-2.209,2.595-2.268c0.201-0.01,0.343,0.003,0.506,0.036l0.083,1.672c-0.186-0.031-0.366-0.043-0.606-0.031
				c-1.25,0.062-2.089,1.052-2.266,2.394c-0.028,0.244-0.056,0.527-0.04,0.83l0.256,5.197l-1.752,0.086L190.3,468.725z"
                      />
                      <path
                        fill="#666666"
                        d="M206.409,474.654l-0.177-3.565l-6.085,0.3l-0.058-1.166l5.432-8.65l1.912-0.094l0.403,8.14l1.833-0.091
				l0.066,1.39l-1.834,0.09l0.178,3.565L206.409,474.654z M206.163,469.699l-0.216-4.371c-0.032-0.686-0.045-1.372-0.039-2.059
				l-0.061,0.002c-0.366,0.787-0.661,1.365-0.994,1.988l-2.981,4.609l0.002,0.041L206.163,469.699z"
                      />
                      <path
                        fill="#666666"
                        d="M219.643,467.298c0.22,4.452-1.312,6.99-4.213,7.134c-2.558,0.125-4.409-2.187-4.663-6.516
				c-0.215-4.391,1.559-6.9,4.219-7.033C217.745,460.748,219.436,463.128,219.643,467.298z M212.542,467.852
				c0.168,3.403,1.31,5.287,2.921,5.207c1.812-0.09,2.576-2.248,2.411-5.592c-0.158-3.223-1.09-5.298-2.922-5.207
				C213.401,462.335,212.365,464.284,212.542,467.852z"
                      />
                      <path
                        fill="#666666"
                        d="M227.089,473.635l-0.178-3.566l-6.083,0.301l-0.059-1.168l5.432-8.648l1.912-0.095l0.401,8.14
				l1.834-0.091l0.068,1.39l-1.833,0.09l0.176,3.566L227.089,473.635z M226.844,468.682l-0.215-4.371
				c-0.034-0.687-0.047-1.373-0.041-2.059l-0.062,0.002c-0.364,0.786-0.659,1.365-0.992,1.986l-2.983,4.611l0.002,0.041
				L226.844,468.682z"
                      />
                    </g> -->
                    <polygon
                      display="none"
                      fill="#BA5B3C"
                      points="201.86,586.939 180.699,587.564 181.589,606.139 202.749,605.514 		"
                    />
                    <path
                      fill="none"
                      d="M204.719,545.934c4.451-0.131,8.164,3.287,8.293,7.633l0.084,2.843c0.128,4.347-3.376,7.978-7.827,8.108
			l-4.478,0.131l-19.865,0.588l-0.191,0.882l29.557-0.873c4.905-0.146,8.999,3.593,9.14,8.351l0.092,3.108
			c0.142,4.758-3.724,8.729-8.631,8.875l-9.084,0.269l-8.407,0.248l-17.121,0.508l-0.238,1.1l30.237-0.893
			c4.259-0.127,7.814,3.297,7.942,7.643l0.084,2.842c0.129,4.348-3.22,7.973-7.479,8.1l-31.47,0.931l0.025,0.875l29.224-0.863
			c3.769-0.11,6.896,2.328,6.989,5.447l0.06,2.038c0.094,3.119-2.888,5.74-6.654,5.852l-33.368,0.986
			c4.535,1.152,13.328,3.113,22.754,3.73c13.364,0.873,21.181-12.305,22.48-17.01c1.28-4.639-0.639-8.881-0.658-8.923l-0.103-0.222
			l0.062-0.236c0.032-0.117,3.16-11.727,4.938-17.277c1.864-5.828,1.864-11.482,0-17.283c-1.942-6.047-6.679-18.033-11.112-24.463
			c-2.317-3.361-4.007-9.69-5.641-15.812c-1.502-5.637-3.058-11.465-4.853-13.46c-0.435-0.483-0.901-0.856-1.396-1.13l1.797,37.505
			L204.719,545.934z"
                    />
                    <polygon
                      fill="#EEA36C"
                      points="180.042,566.139 180.735,566.117 180.926,565.236 180.235,565.258 179.631,565.275
			179.541,565.277 179.35,566.158 179.673,566.148 		"
                    />
                    <polygon
                      fill="none"
                      points="169.856,565.564 169.882,566.438 179.35,566.158 179.541,565.277 		"
                    />
                    <polygon
                      fill="#EEA36C"
                      points="175.586,586.623 174.894,586.643 174.655,587.743 175.347,587.723 176.038,587.702
			176.278,586.602 		"
                    />
                    <path
                      fill="#BA5B3C"
                      d="M203.827,627.995l-0.031-0.651h0.009l-0.333-6.947l-21.16,0.617l0.125,2.617
			c-0.003-0.001-0.006-0.002-0.009-0.002l0.257,5.352c0.237,5.111,4.037,9.084,8.485,8.877l5.034-0.234
			C200.653,637.414,204.066,633.104,203.827,627.995z"
                    />
                  </g>
                </g>
              </svg>
            </h1>
            <p class="error__text">
              {{ $t('error["Такой страницы не существует"]') }}
            </p>
            <v-btn
              class="error__btn"
              color="primary"
              depressed
              :to="`/${hotel_id}`"
            >
              {{ $t('error["Перейти на главную"]') }}
            </v-btn>
          </div>
          <div v-else>
            <h1 class="error__title">
              <svg
                id="error"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="400px"
                height="400px"
                viewBox="0 0 400 400"
                enable-background="new 0 0 400 400"
                xml:space="preserve"
              >
                <g id="svg-mask">
                  <path
                    fill="#f6f5f4"
                    d="M6.789,4.774v325.322h51.02c15.366,15.557,69.323,27.047,133.529,27.047
				c64.207,0,118.164-11.49,133.529-27.047h67.938V4.774H6.789z"
                  />
                </g>
                <g id="svg-hole">
                  <g>
                    <path
                      fill="#C0C0C0"
                      d="M324.867,330.098c2.863-2.897,4.389-5.938,4.389-9.072c0-19.946-61.748-36.116-137.918-36.116
					S53.42,301.078,53.42,321.025c0,3.133,1.524,6.174,4.389,9.07c15.366-15.556,69.323-27.046,133.529-27.046
					C255.545,303.051,309.502,314.541,324.867,330.098z"
                    />
                    <path
                      fill="#333333"
                      d="M324.867,330.098c-15.365-15.557-69.322-27.047-133.529-27.047c-64.206,0-118.163,11.49-133.529,27.047
					c15.366,15.557,69.323,27.045,133.529,27.045C255.545,357.143,309.502,345.652,324.867,330.098z"
                    />
                  </g>
                </g>
                <g id="svg-hand_1_">
                  <g id="svg-hand">
                    <path
                      fill="#F6AF74"
                      stroke="#F6B075"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      d="M224.002,639.541
			c-0.989-4.156-2.063-8.734-3.207-13.664c-0.425-1.831-0.858-3.705-1.302-5.631c-0.957,1.461-2.423,3.426-4.395,5.339
			c-3.47,3.367-8.512,6.571-15.142,6.571c-0.448,0-0.903-0.014-1.364-0.043c-5.405-0.354-11.179-1.168-17.174-2.422
			c-0.208,6.727-0.282,15.629,0.449,21.48c0.872,6.979,3.285,39.989,3.925,48.082c2.282,0.029,4.577,0.046,6.888,0.046
			c14.876,0,31.888-0.616,45.312-1.758C235.236,687.629,226.856,651.527,224.002,639.541z"
                    />
                    <path
                      fill="#EEA36C"
                      d="M225.31,639.23c-0.963-4.043-2.007-8.49-3.116-13.271c-0.551-2.371-1.115-4.821-1.697-7.353l0.002-0.004
			l-0.06-0.258c-2.471-10.757-4.307-18.978-4.307-18.979l-0.202-0.904l-35.377,20.811l-0.023,0.356
			c-0.002,0.039-0.253,3.972-0.429,9.216l-0.019,0.562l0.004,0.001c-0.216,6.8-0.3,15.92,0.451,21.93
			c0.863,6.921,3.27,39.81,3.907,47.896c0.227,0.004,0.45,0.009,0.677,0.012c0.225,0.003,0.448,0.006,0.674,0.009
			c-0.64-8.094-3.053-41.103-3.925-48.082c-0.73-5.853-0.657-14.755-0.448-21.479c0.54,0.113,1.066,0.203,1.603,0.309
			c0.89,0.175,1.78,0.355,2.658,0.512c0.566,0.102,1.121,0.182,1.684,0.273c0.929,0.152,1.859,0.311,2.774,0.439
			c0.448,0.062,0.883,0.107,1.328,0.166c1.007,0.131,2.017,0.267,3.005,0.369c0.035,0.004,0.068,0.006,0.104,0.01
			c1.357,0.141,2.701,0.26,4.019,0.346c0.46,0.029,0.915,0.045,1.363,0.045c0.001,0,0,0,0.001,0c0.819,0,1.611-0.055,2.383-0.146
			c0.036-0.004,0.071-0.01,0.107-0.015c0.341-0.043,0.677-0.093,1.008-0.151c0.057-0.011,0.111-0.021,0.169-0.031
			c0.312-0.06,0.618-0.123,0.921-0.195c0.061-0.014,0.119-0.028,0.179-0.043c0.304-0.075,0.604-0.156,0.897-0.244
			c0.049-0.016,0.098-0.027,0.146-0.043c0.315-0.098,0.627-0.2,0.934-0.311c0.021-0.008,0.045-0.017,0.066-0.023
			c6.483-2.363,10.721-7.646,12.727-10.71c0.442,1.924,0.877,3.798,1.302,5.629c1.144,4.931,2.219,9.509,3.207,13.664
			c2.854,11.985,11.232,48.089,13.99,58.001c0.227-0.02,0.454-0.039,0.681-0.057c0.228-0.021,0.454-0.041,0.683-0.062
			C236.605,687.54,228.182,651.294,225.31,639.23z"
                    />
                    <path
                      fill="#BA5B3C"
                      d="M191.569,372.173c-0.236-5.108-4.036-9.083-8.484-8.875l-5.034,0.233
			c-4.448,0.209-7.861,4.519-7.623,9.628l0.486,10.153l21.119-1.482L191.569,372.173z"
                    />
                    <polygon
                      fill="#eeeeee"
                      points="292.153,374.793 192.031,381.83 170.911,383.312 69.614,390.432 75.595,500.578 176.198,493.645
			197.32,492.189 302.378,484.949"
                    />
                    <path
                      fill="#F6AF74"
                      d="M221.744,563.232c-2.017-6.271-6.721-18.145-11.199-24.64c-4.48-6.495-6.72-25.086-10.545-29.343
			c-0.626-0.695-1.279-1.161-1.934-1.458l1.834,38.283l4.817-0.144c4.451-0.131,8.164,3.287,8.293,7.633l0.084,2.842
			c0.128,4.348-3.376,7.978-7.827,8.109l-4.478,0.131l0.042,0.875l9.457-0.278c4.905-0.146,8.999,3.593,9.14,8.35l0.092,3.109
			c0.142,4.758-3.724,8.729-8.631,8.875l-9.084,0.271l0.053,1.092l4.416-0.131c4.26-0.127,7.815,3.297,7.943,7.644l0.084,2.841
			c0.129,4.348-3.22,7.973-7.479,8.1l-4.074,0.121l0.041,0.875l1.812-0.055c3.77-0.111,6.897,2.328,6.989,5.447l0.061,2.039
			c0.093,3.119-2.888,5.74-6.654,5.851l-1.568,0.046l-21.161,0.625l-11.761,0.35c-0.796,0.022-1.562-0.073-2.277-0.26
			c0,0,5.94,1.797,14.196,3.197c3.627,0.615,7.698,1.154,11.913,1.43c3.458,0.228,6.55-0.447,9.266-1.662
			c8.132-3.639,12.881-12.129,13.906-15.838c1.367-4.951-0.695-9.387-0.695-9.387s3.136-11.646,4.928-17.246
			S223.76,569.503,221.744,563.232z"
                    />
                    <path
                      fill="#EEA36C"
                      d="M222.384,563.025c-2.045-6.361-6.737-18.219-11.286-24.814c-2.186-3.169-3.844-9.385-5.447-15.396
			c-1.613-6.055-3.141-11.775-5.149-14.012c-0.743-0.826-1.57-1.408-2.469-1.75l0.035,0.74l0.037,0.777
			c0.494,0.273,0.962,0.646,1.396,1.13c1.794,1.997,3.349,7.825,4.852,13.46c1.634,6.121,3.321,12.453,5.641,15.812
			c4.435,6.43,9.17,18.418,11.112,24.463c1.864,5.803,1.864,11.457,0,17.285c-1.776,5.551-4.904,17.159-4.938,17.276l-0.062,0.235
			l0.103,0.222c0.02,0.042,1.938,4.284,0.658,8.924c-1.302,4.703-9.116,17.882-22.48,17.009c-9.426-0.615-18.22-2.576-22.754-3.73
			l-1.122,0.033c-0.796,0.022-1.561-0.072-2.277-0.26c-0.22-0.058-0.434-0.128-0.644-0.201l-0.146,0.664l0.596,0.18
			c0.123,0.039,12.531,3.758,26.26,4.656c0.462,0.029,0.915,0.045,1.364,0.045c13.363,0,21.169-13.223,22.499-18.037
			c1.254-4.539-0.218-8.608-0.634-9.616c0.446-1.649,3.229-11.897,4.857-16.991C224.337,575.025,224.337,569.104,222.384,563.025z"
                    />
                    <polygon
                      fill="#BA5B3C"
                      points="198.105,508.57 198.068,507.793 198.031,507.053 197.32,492.189 176.198,493.645 178.74,546.7
			183.574,546.559 184.263,546.537 184.95,546.518 199.902,546.076 		"
                    />
                    <path
                      fill="#F6AF74"
                      d="M175.586,586.623l0.691-0.021l17.121-0.506l8.407-0.248l9.084-0.268c4.907-0.145,8.771-4.118,8.631-8.875
			l-0.092-3.109c-0.141-4.758-4.233-8.496-9.14-8.352l-29.557,0.875l-0.69,0.02l-0.37,0.012l-0.322,0.011l-9.469,0.278l-0.025-0.875
			l9.688-0.285l0.089-0.004l0.604-0.018l0.691-0.02l19.865-0.588l4.478-0.133c4.451-0.131,7.956-3.763,7.826-8.107l-0.084-2.842
			c-0.128-4.348-3.841-7.764-8.292-7.633l-4.817,0.143l-14.95,0.441l-0.688,0.02l-0.688,0.021l-4.833,0.143l-14.771,0.436
			c-4.452,0.131-7.956,3.764-7.827,8.109l0.084,2.842c0.119,4.031,3.323,7.258,7.339,7.602l0.033,1.105
			c-4.032,0.918-6.982,4.482-6.859,8.648l0.093,3.111c0.122,4.166,3.316,8.862,7.396,9.541l0.019,0.604
			c-2.696,1.291-4.527,4.125-4.434,7.357l0.084,2.842c0.129,4.348,3.686,7.77,7.943,7.645l0.965-0.029l0.029,0.979
			c-3.142,0.59-5.459,2.952-5.377,5.709l0.06,2.039c0.067,2.258,1.729,4.151,4.068,4.985c0.209,0.074,0.423,0.146,0.644,0.203
			c0.717,0.187,1.48,0.282,2.277,0.26l1.121-0.032l33.368-0.987c3.768-0.109,6.748-2.73,6.655-5.852l-0.061-2.039
			c-0.093-3.118-3.222-5.559-6.989-5.446l-29.223,0.862l-0.026-0.875l31.471-0.93c4.259-0.125,7.605-3.752,7.478-8.1l-0.084-2.841
			c-0.127-4.347-3.684-7.769-7.942-7.644l-30.236,0.893l-0.692,0.021l-0.692,0.021l-4.14,0.122l-0.032-1.092l4.411-0.131
			L175.586,586.623z"
                    />
                    <path
                      fill="#EEA36C"
                      d="M170.26,621.367c-4.056,0-7.312-2.682-7.413-6.104l-0.06-2.039c-0.078-2.625,1.768-4.986,4.54-6.009
			c-4.433-0.151-7.966-3.739-8.101-8.295l-0.084-2.842c-0.091-3.093,1.461-5.964,4.016-7.546c-4.087-1.262-6.88-5.963-6.997-9.957
			l-0.094-3.11c-0.124-4.213,2.718-7.985,6.827-9.18c-4.104-0.638-7.218-4.054-7.339-8.179l-0.084-2.842
			c-0.14-4.711,3.664-8.658,8.479-8.801l40.748-1.203c4.875-0.15,8.844,3.549,8.983,8.285l0.084,2.842
			c0.067,2.283-0.779,4.457-2.385,6.121c-0.92,0.953-2.026,1.672-3.242,2.125l2.13-0.064c5.335-0.146,9.68,3.857,9.831,9.004
			l0.092,3.109c0.073,2.482-0.854,4.848-2.611,6.654c-1.753,1.803-4.122,2.836-6.671,2.912l-2.606,0.076
			c3.698,0.812,6.468,4.082,6.587,8.105l0.084,2.842c0.068,2.279-0.739,4.447-2.271,6.106c-1.409,1.524-3.276,2.453-5.307,2.647
			c2.795,0.85,4.789,3.064,4.868,5.73l0.062,2.039c0.05,1.713-0.699,3.338-2.109,4.578c-1.374,1.208-3.221,1.906-5.199,1.964
			l-34.49,1.021C170.438,621.365,170.35,621.367,170.26,621.367z M169.459,605.824l0.064,2.226l-0.563,0.104
			c-2.869,0.539-4.899,2.654-4.83,5.029l0.061,2.039c0.081,2.754,2.873,4.918,6.297,4.797l34.491-1.021
			c1.665-0.049,3.211-0.629,4.353-1.631c1.104-0.97,1.69-2.224,1.651-3.529l-0.061-2.038c-0.079-2.691-2.746-4.8-6.07-4.8
			l-0.228,0.004l-29.896,0.884l-0.064-2.22l32.141-0.948c1.877-0.056,3.622-0.854,4.91-2.252c1.293-1.398,1.974-3.23,1.916-5.156
			l-0.084-2.842c-0.115-3.923-3.209-6.994-7.044-6.994l-0.207,0.004l-36.434,1.076l-0.071-2.437l41.08-1.216
			c2.197-0.063,4.238-0.952,5.747-2.504c1.501-1.545,2.294-3.562,2.23-5.68l-0.092-3.109c-0.13-4.4-3.847-7.832-8.447-7.696
			l-41.081,1.213l-0.064-2.217l36.084-1.067c1.977-0.058,3.812-0.863,5.167-2.269c1.352-1.4,2.063-3.229,2.008-5.147l-0.084-2.843
			c-0.117-3.989-3.464-7.125-7.603-6.979l-40.747,1.203c-4.073,0.121-7.293,3.447-7.176,7.418l0.084,2.842
			c0.107,3.645,2.999,6.633,6.727,6.951l0.597,0.051l0.067,2.258l-0.539,0.123c-3.783,0.859-6.448,4.215-6.338,7.975l0.093,3.109
			c0.107,3.689,2.941,8.252,6.835,8.898l0.545,0.09l0.046,1.595l-0.394,0.188c-2.545,1.217-4.136,3.859-4.052,6.731l0.084,2.842
			c0.116,3.923,3.211,6.994,7.046,6.994L169.459,605.824z"
                    />
                    <g>
                      <path
                        d="M146.602,428.122c0.367,5.899-0.837,10.632-3.609,14.196c-2.773,3.563-6.817,5.511-12.129,5.841
				c-5.43,0.338-9.722-1.062-12.878-4.198c-3.155-3.137-4.923-7.73-5.299-13.783c-0.373-6,0.816-10.744,3.57-14.23 c2.753-3.486,6.853-5.399,12.299-5.737c5.295-0.33,9.534,1.095,12.719,4.271C144.458,417.659,146.235,422.205,146.602,428.122z
				M117.228,429.947c0.312,4.992,1.609,8.712,3.896,11.16c2.287,2.449,5.456,3.547,9.506,3.295c4.085-0.254,7.09-1.732,9.015-4.434
				c1.924-2.701,2.731-6.557,2.42-11.566c-0.309-4.959-1.581-8.654-3.814-11.09c-2.235-2.434-5.388-3.525-9.454-3.271
				c-4.085,0.254-7.111,1.736-9.076,4.449C117.751,421.204,116.922,425.021,117.228,429.947z"
                      />
                      <path
                        d="M157.556,418.253l1.114,17.929c0.14,2.252,0.757,3.9,1.852,4.946c1.095,1.045,2.733,1.5,4.919,1.365
				c2.892-0.181,4.956-1.101,6.194-2.765c1.238-1.662,1.745-4.285,1.523-7.865l-0.902-14.523l4.186-0.26l1.718,27.634l-3.453,0.214
				l-0.836-3.668l-0.228,0.014c-0.771,1.416-1.896,2.531-3.373,3.349s-3.188,1.287-5.14,1.407c-3.361,0.209-5.928-0.432-7.699-1.926
				c-1.771-1.492-2.768-3.994-2.985-7.508l-1.124-18.078L157.556,418.253z"
                      />
                      <path
                        d="M199.924,443.867c-1.799,0.111-3.463-0.118-4.991-0.689c-1.528-0.572-2.841-1.515-3.935-2.83l-0.304,0.018
				c0.303,1.601,0.498,3.125,0.589,4.57l0.707,11.371l-4.187,0.26l-2.488-40.039l3.403-0.211l0.815,3.746l0.2-0.013
				c0.981-1.579,2.166-2.75,3.555-3.511c1.387-0.762,3.005-1.2,4.854-1.314c3.666-0.227,6.57,0.85,8.719,3.23
				c2.146,2.381,3.361,5.832,3.645,10.354c0.28,4.539-0.513,8.127-2.382,10.767C206.254,442.212,203.522,443.645,199.924,443.867z
				M197.76,418.816c-2.824,0.176-4.818,1.084-5.981,2.727c-1.162,1.643-1.655,4.169-1.478,7.583l0.059,0.935
				c0.24,3.883,1.062,6.621,2.459,8.213c1.398,1.592,3.526,2.297,6.385,2.121c2.387-0.148,4.196-1.231,5.43-3.248
				c1.231-2.018,1.743-4.724,1.534-8.119c-0.216-3.446-1.057-6.049-2.522-7.805C202.175,419.467,200.214,418.665,197.76,418.816z"
                      />
                      <path
                        d="M236.034,433.549c0.158,2.572-0.676,4.614-2.505,6.129c-1.829,1.516-4.477,2.379-7.938,2.594
				c-3.665,0.229-6.559-0.174-8.681-1.207l-0.242-3.883c1.369,0.59,2.828,1.031,4.374,1.323c1.544,0.293,3.021,0.394,4.437,0.306
				c2.186-0.136,3.843-0.588,4.977-1.359c1.135-0.77,1.655-1.871,1.566-3.299c-0.066-1.076-0.591-1.967-1.569-2.674
				c-0.981-0.707-2.856-1.498-5.63-2.371c-2.63-0.799-4.511-1.521-5.64-2.168s-1.983-1.408-2.571-2.283
				c-0.586-0.875-0.918-1.942-0.995-3.203c-0.141-2.252,0.666-4.088,2.417-5.504s4.225-2.225,7.417-2.422
				c2.976-0.186,5.921,0.238,8.837,1.272l-1.275,3.496c-2.846-0.97-5.393-1.386-7.646-1.246c-1.984,0.124-3.46,0.527-4.432,1.212
				c-0.969,0.684-1.42,1.573-1.354,2.666c0.046,0.739,0.273,1.357,0.686,1.854c0.411,0.498,1.05,0.957,1.92,1.375
				c0.871,0.419,2.525,0.998,4.97,1.742c3.352,0.99,5.643,2.053,6.867,3.191C235.25,430.229,235.92,431.717,236.034,433.549z"
                      />
                      <path
                        d="M255.951,437.195c-0.143-2.285,0.797-3.491,2.813-3.616c0.976-0.062,1.745,0.187,2.312,0.741
				c0.564,0.557,0.881,1.389,0.951,2.498c0.067,1.076-0.149,1.929-0.65,2.56s-1.229,0.976-2.188,1.035
				c-0.874,0.056-1.611-0.166-2.218-0.66C256.368,439.258,256.028,438.406,255.951,437.195z M259.873,429.436l-2.646,0.164
				l-2.947-26.621l5.222-0.324L259.873,429.436z"
                      />
                    </g>
                    <polygon
                      display="none"
                      fill="#BA5B3C"
                      points="201.86,586.939 180.699,587.564 181.589,606.139 202.749,605.514 		"
                    />
                    <path
                      fill="none"
                      d="M204.719,545.934c4.451-0.131,8.164,3.287,8.293,7.633l0.084,2.843c0.128,4.347-3.376,7.978-7.827,8.108
			l-4.478,0.131l-19.865,0.588l-0.191,0.882l29.557-0.873c4.905-0.146,8.999,3.593,9.14,8.351l0.092,3.108
			c0.142,4.758-3.724,8.729-8.631,8.875l-9.084,0.269l-8.407,0.248l-17.121,0.508l-0.238,1.1l30.237-0.893
			c4.259-0.127,7.814,3.297,7.942,7.643l0.084,2.842c0.129,4.348-3.22,7.973-7.479,8.1l-31.47,0.931l0.025,0.875l29.224-0.863
			c3.769-0.11,6.896,2.328,6.989,5.447l0.06,2.038c0.094,3.119-2.888,5.74-6.654,5.852l-33.368,0.986
			c4.535,1.152,13.328,3.113,22.754,3.73c13.364,0.873,21.181-12.305,22.48-17.01c1.28-4.639-0.639-8.881-0.658-8.923l-0.103-0.222
			l0.062-0.236c0.032-0.117,3.16-11.727,4.938-17.277c1.864-5.828,1.864-11.482,0-17.283c-1.942-6.047-6.679-18.033-11.112-24.463
			c-2.317-3.361-4.007-9.69-5.641-15.812c-1.502-5.637-3.058-11.465-4.853-13.46c-0.435-0.483-0.901-0.856-1.396-1.13l1.797,37.505
			L204.719,545.934z"
                    />
                    <polygon
                      fill="#EEA36C"
                      points="180.042,566.139 180.735,566.117 180.926,565.236 180.235,565.258 179.631,565.275
			179.541,565.277 179.35,566.158 179.673,566.148 		"
                    />
                    <polygon
                      fill="none"
                      points="169.856,565.564 169.882,566.438 179.35,566.158 179.541,565.277 		"
                    />
                    <polygon
                      fill="#EEA36C"
                      points="175.586,586.623 174.894,586.643 174.655,587.743 175.347,587.723 176.038,587.702
			176.278,586.602 		"
                    />
                    <path
                      fill="#BA5B3C"
                      d="M203.827,627.995l-0.031-0.651h0.009l-0.333-6.947l-21.16,0.617l0.125,2.617
			c-0.003-0.001-0.006-0.002-0.009-0.002l0.257,5.352c0.237,5.111,4.037,9.084,8.485,8.877l5.034-0.234
			C200.653,637.414,204.066,633.104,203.827,627.995z"
                    />
                  </g>
                </g>
              </svg>
            </h1>
            <div >
              <div
                v-html="
                  page_404[
                    lang
                  ].content
                "
                style="margin-bottom:30px;"
              ></div>
              <v-btn
                class="error__btn"
                color="primary"
                depressed
                :to="`/${hotel_id}`"
              >
                {{ $t('error["Перейти на главную"]') }}
              </v-btn>
            </div>
          </div>
        </v-container>
      </div>
    </div>
  </v-app>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  layout: "public",
  data() {
    return {
      content: false,
      hotel_id:null,
      hotelAdvanced:null,
      page_404:null,
      lang:'en'
    };
  },
  computed: {
  },
  methods:{
    async getAdvanced(){
      this.hotelAdvanced = await axios.get("get-filtered-settings", {
        params: {
          hotel_id: this.hotel_id,
          columns: ["advanced"],
        },
      });
    },
  },
  async mounted() {
    this.hotel_id = this.$route.path.slice(3,7);
    await this.getAdvanced();
    if(this.hotelAdvanced.status === 201){
      this.lang = this.hotelAdvanced.data.default_lang;
      this.page_404 = this.hotelAdvanced.data.global.page_404;
      this.content = true;
    }
    let error = window.Snap.select("#error"),
      hole = window.Snap.select("#svg-hole"),
      hand = window.Snap.select("#svg-hand"),
      mask = window.Snap.select("#svg-mask");

    function onSVGLoaded() {
      function animOn() {
        hand.animate(
          {
            transform: "t10,-300, r0",
          },
          4500,
          window.mina.easeinout,
          animOut
        );

        mask.animate(
          {
            transform: "t-10,300, r0",
          },
          4500,
          window.mina.easeinout
        );
      }

      function animOut() {
        hand.animate(
          {
            transform: "t-10,-305, r0",
          },
          4500,
          window.mina.easeinout,
          animOn
        );

        mask.animate(
          {
            transform: "t10,305, r0",
          },
          4500,
          window.mina.easeinout
        );
      }

      function open() {
        clearTimeout(timer);
        hand.animate(
          {
            transform: "t0,-300",
          },
          800,
          window.mina.backout,
          animOn
        );

        mask.animate(
          {
            transform: "t0,300",
          },
          800,
          window.mina.backout
        );
      }
      let timer = setTimeout(open, 1000);

      hand.attr({
        mask: mask,
      });
    }

    onSVGLoaded();
  },
};
</script>

<style lang="scss" scoped>
#error {
  margin: 0 auto;
  display: block;
}
.page {
  height: 100%;
}
.error__content {
  height: 100%;
  text-align: center;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.error__title {
  margin: 0 0 30px;
  font-size: 100px;
  line-height: 1;
}

.error__text {
  margin: 0 0 30px;
  font-size: 24px;
  line-height: 1;
}

.error__btn {
}
</style>
