<template>
    <div id="app">
        <vue-progress-bar></vue-progress-bar>
        <component :is="layout">
            <router-view/>
        </component>
        <div class="loading v-application" v-if="$store.state.constructorLoader">
          <img
              alt="loader"
              class="loading__icon"
              src="@/assets/img/logo__loader.svg"
              style="height: 100px; width: 100px; margin-bottom: 50px"
          />
          <div>Please wait a little bit.</div>
          <div>We are saving your project!</div>
        </div>
    </div>
</template>

<script>
import store from '@/store'

export default {
    computed: {
        layout() {
            return this.$route.meta.layout || 'empty-layout'
        }
    },
  data(){
      return{
        prompt: false
      }
  },
    created () {
      this.$store.state.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (this.$workbox) {
        this.$workbox.addEventListener("waiting", () => {
          this.prompt = true;
        });
      }

        this.$Progress.start()
        this.$router.beforeEach((to, from, next) => {
            if (to.meta.progress !== undefined) {
                let meta = to.meta.progress
                this.$Progress.parseMeta(meta)
            }
            this.$Progress.start()
            next()
        })
        this.$router.afterEach((to, from) => {
            this.$Progress.finish()
        })

        this.$axios.interceptors.request.use(response => {
            this.$Progress.start()
            return response;
        }, function (error) {
            return Promise.reject(error);
        });

        this.$axios.interceptors.response.use(response => {
            this.$Progress.finish()
            return response;
        }, function (error) {

            if (error) {
                if (error.response) {
                    if (error.response.status === 401) {

                        store.dispatch('logout')
                    }
                }
            }
            return Promise.reject(error);
        });

    },
  methods:{
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    }
  },
    mounted () {
        //  [App.vue specific] When App.vue is finish loading finish the progress bar
        this.$Progress.finish()
    },
}
</script>
<style lang="scss">
  @import "./assets/custom_fonts/Acrom/stylesheet.css";
  @import "./assets/custom_fonts/Arial/stylesheet.css";
  @import "./assets/custom_fonts/BebasNeue/stylesheet.css";
  @import "./assets/custom_fonts/Circe/stylesheet.css";
  @import "./assets/custom_fonts/DINPro/stylesheet.css";
  @import "./assets/custom_fonts/Geometria/stylesheet.css";
  @import "./assets/custom_fonts/GothamPro/stylesheet.css";
  @import "./assets/custom_fonts/HelveticaNeueCyr/stylesheet.css";
  @import "./assets/custom_fonts/MyriadPro/stylesheet.css";
  @import "./assets/custom_fonts/ProximaNova/stylesheet.css";
  @import "./assets/custom_fonts/SanFrancisco/stylesheet.css";
  .tox-notifications-container{
    display: none;
    opacity: 0;
  }
  .loading {
    position: fixed;
    top: 0;
    z-index:9999;
    left: 0;
    width: 100%;
    height: 100%;
    color: #7f7f7f;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__icon {
      animation: 1s linear 0s normal none infinite running circle;
    }
    @keyframes circle {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
</style>
