<template>
    <v-app class="app app_admin">
        <div class="page">
            <div class="main" :style="`padding:${$route.name === 'Constructor'?'40px 0 20px':'40px 30px 20px'};margin-left:${left};width:${width};`" v-if="$store.state.profile.name">
                <div class="header__constructor" style="padding-right: 30px;">
                    <span style="margin-right: 15px;font-weight: bold;" id="userName">{{$store.state.profile.name}}</span>
                    <v-menu
                            open-on-hover
                            bottom
                            offset-y
                            transition="slide-y-transition"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar class="avatar">
                                <img
                                        :src="$store.state.profile.avatar ? `${VUE_APP_STORAGE_URL+$store.state.profile.avatar}` : require('@/assets/icons/user_placeholder.svg')"
                                        alt="John"
                                        v-bind="attrs"
                                        v-on="on"

                                >
                            </v-avatar>
                        </template>

                        <v-list>
                            <v-list-item class="menu__item">
                                <v-list-item-title @click="createproperty" >Create property</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="menu__item">
                                <v-list-item-title @click="listproperty">My Facilities</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="menu__item">
                                <v-list-item-title @click="logout">Sign Out</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                </div>
                <slot />
                <div v-if="!$route.path.includes('constructor')" class="footer" style="height:70px;position: relative;">
                    <ul>
                        <li style="cursor: default;">© 2023 Tourist, Inc.</li>
                        <li @click="routeTo('Legal')">Privacy & Legal</li>
                        <li @click="routeTo('Contact')">Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
    import store from "@/store";

    export default {
        data: () => ({
            hideMenu:false,
        }),
        async mounted() {
            const APP_VERSION = '12';
            if(localStorage.getItem('app_version')){
                if(localStorage.getItem('app_version') !== APP_VERSION){
                    let keys = await caches.keys();
                    for(let i = 0;i<keys.length;i++){
                        await caches.delete(keys[i]);
                    }
                    localStorage.setItem('app_verion',APP_VERSION);
                }
            }else {
                let keys = await caches.keys();
                for(let i = 0;i<keys.length;i++){
                    await caches.delete(keys[i]);
                }
                localStorage.setItem('app_verion',APP_VERSION);
            }
            if(this.$store.state.profile.name){
                await store.dispatch('getProfile');
            }
        },
        computed:{
            VUE_APP_STORAGE_URL(){
                return process.env.VUE_APP_STORAGE_URL;
            },

            width(){
                if(this.hideMenu){
                    return 'calc(100% - 105px);'
                }else{
                    return 'calc(100% - 250px)';
                }
            },
            left(){
                if(this.hideMenu){
                    return '105px'
                }else{
                    return '250px'
                }
            },
        },

        methods:{
            hide(hide){
                this.hideMenu = hide;
            },
            async routeTo(page){
                await this.$router.push({name:page});
            },
            async logout() {
                this.$store.dispatch('logout');
                await this.$router.push('/')
            },
            async createproperty(){
                await this.$router.push({
                    name: "New property",
                    params: { lang: this.$route.params.lang},
                });
            },
            async listproperty(){
                await this.$router.push({
                    name: "My property",
                    params: { lang: this.$route.params.lang},
                });
            }
        }
    };
</script>
<style lang="scss">
    .app_admin {
        @import '@/scss/style';
    }
    .footer{
        display: flex;
        justify-content: center;
        align-items: center;
        ul{
            display: flex;
            width:100%;
            justify-content: center;
            align-items: center;
            list-style: none;
            li{
                color:#5c5d61;
                font-size:14px;
                font-weight: 500;
                cursor: pointer;
                padding:0 20px;
            }
        }
    }
    .header__constructor{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .help{
            cursor: pointer;
            &:hover{
                color:#000;
            }

        }
        .avatar{
            height: 60px;
            width:60px;
            border:1px solid #eee;
            &:hover{
                border:1px solid #000;
            }
        }
    }
    .menu__item{
        cursor: pointer;
        .v-list-item__title{
            color:#7f8385 !important;

        }
        &:hover{
            .v-list-item__title{
                color:#000 !important;
            }
        }
    }
</style>
