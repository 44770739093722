<template>
  <div>
    <div v-if="$route.name !== 'Constructor'&&$route.name !== 'Labels'&&$route.name !== 'New property'&&$route.name !== 'Clone property'" style="background: #fff;position:relative;z-index:99;display: flex;justify-content: center;align-items: center;width: 100%;padding: 5px 0;box-shadow: 0 0 0 1px rgba(68,92,116,0.02),0 2px 8px rgba(57,76,96,0.15);">
      <div  @click="openMenu(menu)" :style="`color:${menu.active?'#007db5':'#7f8385'};`" style="flex:1;display: flex;flex-direction: column;align-items: center;justify-content: center;" v-for="menu in mobileMenu" :key="menu.name">
        <div><v-icon :color="`${menu.active?'#007db5':'#7f8385'}`">{{menu.icon}}</v-icon></div>
        <div style="font-size:12px;">{{menu.name}}</div>
      </div>
    </div>
    <div v-if="$route.name === 'Constructor'" style="display: flex;width: 100vw;overflow:hidden;padding: 5px;z-index:99;box-shadow: 0 0 0 1px rgba(68,92,116,0.02),0 2px 8px rgba(57,76,96,0.15);">
        <div  @click="openConstructorMenu(menu)" :style="`color:${menu.active?'#007db5':'#7f8385'};`" style="flex:1;display: flex;flex-direction: column;align-items: center;justify-content: center;" v-for="menu in filteredMobileMenuConstructor" :key="menu.name">
          <div><v-icon :color="`${menu.active?'#007db5':'#7f8385'}`">{{menu.icon}}</v-icon></div>
          <div style="font-size:12px;">{{menu.name}}</div>
        </div>
      </div>
      <DialogPreview :dialogPreview="$store.state.dialogPreview" @closeDialog="closeDialogPreview"/>
      <SaveLanding  :dialog="dialogSave" @closeDialog="closeDialogSave" />
      <v-bottom-sheet v-model="sheet" scrollable>
      <v-list
          style="border-top-right-radius: 5px; border-top-left-radius: 5px"
      >
        <v-list-item v-if="selectedMenuItem&&typeof selectedMenuItem.content[landing.current_lang][selectedMenuItem.type-1].styleSettings !== 'undefined' &&selectedMenuItem.type!==2&&selectedMenuItem.type!==1&&selectedMenuItem.type!==5&&selectedMenuItem.type!==6&&selectedMenuItem.type!==3" style="cursor: pointer" @click="openStyles">
          <v-list-item-title>Styles</v-list-item-title>
        </v-list-item>
        <v-list-item  @click="openMenuTemplates">Templates</v-list-item>
        <v-list-item style="cursor: pointer;color:#ff2b2b" @click="openDeleteMenuModal">
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="sheetConst" scrollable>
      <v-list
          style="border-top-right-radius: 5px; border-top-left-radius: 5px"
      >
        <v-list-item
            @click="changePage('start',0)"
            class="header__tab"
            :class="{ activeTab: $store.state.selectedPage === 'start' }"
        >
          Start
        </v-list-item>
        <v-list-item
            @click="changePage('home',2)"
            class="header__tab"
            :class="{ activeTab: $store.state.selectedPage === 'home' && $store.state.tab === 2 }"
        >
          {{ $t('constructor["Menu"]') }}
        </v-list-item>
        <v-list-item
            @click="changePageBranding()"
            class="header__tab"
            :class="{ activeTab: $store.state.selectedPage === 'branding' }"
        >
          Design
        </v-list-item>
        <v-list-item
            @click="changePage('reviews',3)"
            class="header__tab"
            :class="{ activeTab: $store.state.selectedPage === 'reviews' }"
        >
          {{ $t('constructor["Reviews"]') }}
        </v-list-item>
        <v-list-item
            @click="changePage('advanced',5)"
            class="header__tab"
            :class="{ activeTab: $store.state.selectedPage === 'advanced' }"
        >
          {{ $t('constructor["Advanced"]') }}
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
    <PopupMenuTemplate :show="menuTemplate" @closeModal="closeMenuTemplates"/>
  </div>
</template>

<script>
  import Sidebar from "../Sidebar";
  import {mapMutations, mapState} from 'vuex';
  import {createMenuItem} from "@/store/landing";
  export default {
    name: "MobileMenu",
    components: {
      DialogPreview:() => import("@/components/Constructor/Preview/DialogPreview"),
      SaveLanding:() => import("@/components/Constructor/SaveLanding"),
      PopupMenuTemplate: () => import("@/components/Popup/PopupMenuTemplate.vue")
    },
    created() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    data(){
      return{
        menuTemplate:false,
        sheetConst:false,
        sheet:false,
        dialogSave:false,
        swiperOptions:{
          slidesPerView: 'auto',
          spaceBetween: 10,
        },
        branding_pages:[
          {
            id:15,page:'',name:'Menu Styles'
          },
          {
            id:0,page:'home',name:'Header',
          },
          {
            id:1,page:'home',name:'Logo',
          },
          {
            id:2,page:'home',name:"Text Logo",
          },
          {
            id:3,page:'home',name:'Footer',
          },
          {
            id:4,page:'home',name:'Footer Text',
          },
          {
            id:5,page:'home',name:'Background',
          },
          {
            id:6,page:'lining',name:'Lining',
          },
          {
            id:7,page:'loader',name:'Loader',
          },
          {
            id:8,page:'home',name:'Modal Banner',
          },
          {
            id:9,page:'home',name:'Bottom Banner',
          },
          {
            id:10,page:'intro',name:'Intro Banner',
          },
          {
            id:11,page:'home',name:'Slider',
          },
          {
            id:12,page:'home',name:'Bottom Menu',
          },
          {
            id:13,page:'topMenu',name:"Top Menu",
          },
          {
            id:14,page:'home',name:'Socials'
          },
          {
            id:16,page:'sidebar',name:'Sidebar'
          },
          {
            id:17,page:'home',name:'Events'
          },
          {
            id:18,page:'home',name:"Templates"
          }
        ],
        isMobile:false,
        showMenu:false,
        mobileMenuConstructor:[
          {
            id:3,
            show:false,
            name:"Settings",
            icon:"mdi-cog",
            link:"",
            active:false
          },
          {
            id:1,
            name:"Preview",
            show:true,
            icon:"mdi-eye",
            link:"",
            active:false
          },
          {
            id:2,
            name:"Save",
            show:true,
            icon:"mdi-application-export",
            link:"",
            active:false
          },
          {
            id:4,
            name:"Menu",
            show:true,
            icon:"mdi-dots-horizontal",
            link:"",
            active:false
          },
        ],
        mobileMenu:[
          {
            id:0,
            name:"Main",
            icon:"mdi-home",
            link:"welcome",
            active:true
          },
          {
            id:1,
            name:"My property",
            icon:"mdi-folder",
            link:"my-objects",
            active:false
          },
        ],
        mobileMenuLabels:[
          {
            id:0,
            name:"Preview",
            icon:"mdi-eye",
            link:"",
            active:false
          },
          {
            id:1,
            name:"Save",
            icon:"mdi-application-export",
            link:"",
            active:false
          },
          {
            id:2,
            name:"Download",
            icon:"mdi-download",
            link:"",
            active:false
          },
        ]
      }
    },
    watch:{
      '$route.name'(val){
        for(let i = 0;i<this.mobileMenu.length;i++){
          this.mobileMenu[i].active = false;
        }
        if(val === 'My property'){
          this.mobileMenu[1].active = true;
        }
        if(val === 'welcome'){
          this.mobileMenu[0].active = true;
        }
      }
    },
    computed:{
      ...mapState(['landing']),
      ...mapState("menu", ["selectedMenuItem"]),
      ...mapState("landing", [
        "menu"
      ]),
      filteredMobileMenuConstructor(){
        return this.mobileMenuConstructor.map((item) => {
          if(item.id===1&&this.showFull){
            item.show = false;
          }else {
            item.show = true;
          }
          if(item.id === 3&&!!this.selectedMenuItem&&this.$store.state.page === this.selectedMenuItem.page){
            item.show = true;
          }
          else if(item.id === 3){
            item.show = false;
          }
          return item;
        }).filter(x => x.show);
      },
      authMenu() {
        return this.$store.state.menu.list.filter((item) => item.auth_switch);
      },
      noAuthMenu() {
        return this.$store.state.menu.list.filter((item) => !item.auth_switch);
      },
      showFull() {
        return (
            (this.landing.order.tab === 6 && this.$store.state.page === "order") ||
            (this.landing.order.tab === 3 && this.$store.state.page === "order") ||
            (this.landing.advanced.tab === 0 &&
                this.$store.state.page === "advanced") ||
            (this.landing.advanced.tab === 2 &&
                this.$store.state.page === "advanced") ||
            (this.landing.advanced.tab === 4 &&
                this.$store.state.page === "advanced") ||
            (this.landing.advanced.tab === 5 &&
                this.$store.state.page === "advanced") ||
            (this.landing.advanced.tab === 7 &&
                this.$store.state.page === "advanced") ||
            (this.landing.advanced.tab === 8 &&
                this.$store.state.page === "advanced") ||
            (this.landing.advanced.tab === 11 &&
                this.$store.state.page === "advanced") ||
            (this.landing.advanced.tab === 13 &&
                this.$store.state.page === "advanced") ||
            (this.landing.advanced.tab === 14 &&
                this.$store.state.page === "advanced") ||
            (this.landing.advanced.tab === 15 &&
                this.$store.state.page === "advanced") ||
            (this.landing.advanced.tab === 10 &&
                this.$store.state.page === "advanced") ||
            this.$store.state.page === 'no_preview'
        );
      },
    },
    methods:{
      ...mapMutations("menu", ["setSelectedMenuItem", "setMenuList"]),
      openMenuTemplates(){
        this.menuTemplate = true;
      },
      closeMenuTemplates(){
        this.sheet = false;
        this.menuTemplate = false;
      },
      changePageBranding() {
        this.$store.state.selectedMenuTrigger = false
        if (this.$store.state.branding_active_tab === 6) {
          this.changePage("lining");
        } else if (this.$store.state.branding_active_tab === 9) {
          this.changePage("intro");
        } else {
          this.changePage("home");
        }
        this.$store.state.selectedPage = "branding";
        this.$store.state.tab = 1;
        this.$store.commit('back',true);
      },
      changePage(page,index) {
        this.$store.state.selectedMenuTrigger = false;
        this.$store.state.tab = index;
        this.$store.state.selectedPage = page;
        if (this.selectedMenuItem) {
          const candidate = this.$store.state.menu.list.find(
              (item) => item.id === this.selectedMenuItem.id
          );
          if (candidate) {
            const candidateIndex = this.$store.state.menu.list.indexOf(candidate);
            if (candidateIndex >= 0) {
              this.$store.state.menu.list.splice(
                  candidateIndex,
                  1,
                  JSON.parse(JSON.stringify(this.selectedMenuItem))
              );
            }
          }
        }
        this.setSelectedMenuItem(null);
        this.$store.commit("setPage", page);
        this.sheetConst = false;
        this.$store.commit('back',true);
      },
      openDeleteMenuModal(){
        this.sheet = false;
        this.$store.state.deleteMenuItemTrigger = true
      },
      openStyles(){
        this.sheet = false;
        this.$store.state.styleMenu = true
      },
      closeDialogSave(){
        this.dialogSave = false;
      },
      closeDialogPreview(){
        this.$store.state.dialogPreview = false;
      },
      openConstructorMenu(menu){
        if(menu.id === 1){
          this.$store.state.dialogPreview = true;
        }
        if(menu.id === 2){
          this.dialogSave = true;
        }
        if(menu.id === 3){
          if(this.$store.state.selectedMenuTrigger){
            this.sheet = true;
          }
        }
        if(menu.id === 4){
          this.sheetConst = true;
        }
      },
      changeType(content, ind) {
        if (content.page === "reviews") {
          this.landing.reviews.tab = 0;
          this.$store.commit("setPage",'reviews');
        }
        this.menu.active_tab = 1;
        content.type = ind + 1;
        this.active_menu_tab = ind + 1;
        if (content.type === 2 || content.type === 3) {
          this.$store.commit("setPage", "home");
        }
        else if (content.type === 5) {
          let item = this.$store.state.menu.list.find((x) => x.id === content.id);
          content.content[this.landing.current_lang][4].content.menu.map((x) => {
            x.hidden = true;
            x.flipped = false;
          });
          const candidate = content.content[
              this.landing.current_lang
              ][4].content.menu.find((x) => x.hidden === false);
          if (candidate) {
            if (candidate.flipped) {
              this.flipped = true;
              this.selectedMenu = candidate;
              this.$store.commit("setPage", item.page + "/" + candidate.id);
            } else {
              this.$store.commit("setPage", item.page);
            }
          } else {
            this.$store.commit("setPage", item.page);
          }
        } else {
          this.$store.commit("setPage", content.page);
        }
        if (!this.selectedMenuItem) {
          this.flipped = false;
          this.setSelectedMenuItem(JSON.parse(JSON.stringify(content)));
        } else {
          this.flipped = false;
          const candidate = this.$store.state.menu.list.find(
              (item) => item.id === this.selectedMenuItem.id
          );
          if (candidate) {
            const candidateIndex = this.$store.state.menu.list.indexOf(candidate);
            if (candidateIndex >= 0) {
              this.$store.state.menu.list.splice(
                  candidateIndex,
                  1,
                  JSON.parse(JSON.stringify(this.selectedMenuItem))
              );
            }
          }
          this.setSelectedMenuItem(JSON.parse(JSON.stringify(content)));
          this.forceRerender();
        }
      },
      forceRerender() {
        // Removing my-component from the DOM
        this.$store.state.renderComponent = false;

        this.$nextTick(() => {
          // Adding the component back in
          this.$store.state.renderComponent = true;
        });
      },
      addNewButton() {
        const button = createMenuItem(this.landing.menu.list.length);
        this.landing.advanced.selected_langs.forEach((lang) => {
          if (!button.content[lang]) {
            button.content[lang] = button.content["en"];
          }
        });
        this.landing.menu.list.push(button);
      },
      openStyle() {
        this.$store.commit("mainPageStyles/setGlobalRadius", this.landing.menu.radius);
        this.$store.commit(
            "mainPageStyles/setGlobalShadowSize",
            this.landing.menu.shadowSize
        );
        this.$store.commit(
            "mainPageStyles/setGlobalShadowColor",
            this.landing.menu.shadowColor
        );
        this.$store.commit(
            "mainPageStyles/setGlobalIconSize",
            this.landing.menu.icon_size
        );
        this.$store.commit("mainPageStyles/setList", JSON.parse(JSON.stringify(this.landing.menu.list)));
        this.$store.commit("setPage", "menuStyles");
        this.$store.commit('mainPageStyles/setStylesOpen',true);
      },
      activeMenu(id){
        return id === this.$store.state.active_branding_id
      },
      changeBrandingTab(item){
        this.$store.state.branding_active_tab = item.id;
        this.$store.state.active_branding_id = item.id;
        if(item.id === 15){
          this.openStyle()
        }else{
          this.$store.commit('setPage',item.page);
        }
      },
      openMenu(menu){
        if(menu.id === 2){
          this.showMenu = !this.showMenu;
        }
        if(menu.id === 1){
          this.$router.push('/objects');
        }
        if(menu.id === 0){
          this.$router.push(`/welcome`);
        }
        for(let i = 0;i<this.mobileMenu.length;i++){
          this.mobileMenu[i].active = false;
        }
        menu.active = !menu.active;
      }
    }
  }
</script>

<style lang="scss">

</style>
