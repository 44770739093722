<template>
  <div style="padding: 15px;">
    This page was not found. Go to <router-link v-external-link :to="{name:'Landing'}">main page</router-link>
  </div>
</template>
<script  lang="js">
export default {
  name:"GoToMainPage",
  mounted() {
    document.title = 'This page was not found.'
  }
}
</script>
<style scoped lang="scss">

</style>
