import Vue from "vue";
import moment from "moment";
export default {
  namespaced: true,
  state: {
    list: [],
    item: {
      name: "",
      address: "",
      custom_id:""
    },
    status: "",
  },
  mutations: {
    setList(state, list) {
      state.list = list.sort((a,b) => +moment(b.created_at).format('x') - +moment(a.created_at).format('x'));
    },
    setItem(state, item) {
      state.item = item;
    },
    deleteItem(state, id) {
      state.list = state.list.filter((item) => item.id !== id);
    },
    setStatus(state, status) {
      state.status = status;
    },
  },
  actions: {
    getList({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios
          .get("my-objects")
          .then((response) => {
            const reverse = response.data.reverse();
            commit("setList", reverse);
            resolve(reverse);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    create({ commit }, object) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios
          .post("create-hotel", object)
          .then((response) => {
            commit("setItem", response.data);
            commit("setStatus", "create");
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    clone({ commit }, object) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios
          .get(`clone-hotel?hotel_id=${object.id}&address=${object.address}&name=${object.name}&tariff_id=${object.tariff_id}`)
          .then((response) => {
            object.id = response.data.id;
            commit("setItem", object);
            commit("setStatus", "clone");
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    edit({ commit }, object) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios
          .patch("edit-hotel", object)
          .then((response) => {
            // console.log(response)
            commit("setItem", object);
            commit("setStatus", "edit");
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    deleteItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios
          .delete("delete-hotel", {
            params: {
              id: id,
            },
          })
          .then((response) => {
            // console.log(response)
            commit("deleteItem", id);
            resolve(response);
          })
          .catch((error) => {
            // console.log(error.response)
            reject(error.response);
          });
      });
    },
  },
};
