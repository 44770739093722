<template>
  <div>
    <div style="padding:15px;">Loading...</div>
  </div>
</template>

<script>
import HotelService from "@/services/hotel.service";

export default {
  name: "AuthDriverProvider",
  async created(){
    console.log(this.$route);
    if(this.$route.query.code){
      const data = await HotelService.getUserTokenByCode(this.$route.params.driver,this.$route.query.code);
      this.$store.commit("setToken", data.token);
      setTimeout(() => {
        console.log(localStorage.getItem('token'));
        this.$router.push("/welcome");
      },3000)
    }
  },
}
</script>

<style scoped lang="scss">

</style>
