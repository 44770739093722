<template>
  <div>
    <div  style="display: flex;justify-content: space-between;align-items: center;width: 100%;padding: 15px;box-shadow: 0 0 0 1px rgba(68,92,116,0.02),0 2px 8px rgba(57,76,96,0.15);">
      <div @click="back">
        <v-icon>mdi-chevron-left</v-icon>
      </div>
      <div style="text-transform: capitalize;display: flex;align-items:center;justify-content: center;">
        <span style="font-weight: bold">{{$route.name}}</span>
        <div v-if="$route.name === 'Constructor'" style="margin-left: 15px;">
              {{ currentName }}
      </div>
      </div>
      <MenuIcon/>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
  export default {
    name: "MobileHeader",
    components:{
      MenuIcon:() => import('@/components/Mobile/components/MenuIcon')
    },
    data(){
      return{
        sheet:false
      }
    },
    computed:{
      ...mapState(['landing']),
      ...mapState(["objects"]),
      currentName() {
        const candidate = this.objects.list.find(
            (object) => object.id === this.objects.item.id
        );
        if (candidate) {
          return candidate.name;
        } else {
          return "";
        }
      },
    },
    methods:{
      ...mapMutations("menu", ["setSelectedMenuItem"]),
      openConstructorMenu(){
        if(this.$route.name === 'Constructor'){
          this.sheet = true;
        }
      },
      back(){
        if(this.$route.name === 'Constructor'){
          if(this.$store.state.page === 'start'){
            this.$router.go(-1);
          }else{
            this.$store.commit('back',false);
          }
        }else{
          if(this.$route.name === 'Labels'&&this.$store.state.labelOpen){
            this.$store.state.labelOpen = false;
          }else{
            this.$router.go(-1);
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
