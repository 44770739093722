<template>
    <div class="landing">
        <slot/>
    </div>
</template>

<script>
export default {
    data: () => ({
        //
    }),
  async mounted(){

  }
};
</script>

<style lang="scss">
@import '@/scss/landing/normalize';
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    width: 100%;
    height: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    width: 100%;
    height: 100%;
}

@import '@/scss/landing/libs/magnific-popup';
</style>

<style lang="scss" scoped>
.landing {
    min-width: 320px;
    background-color: #fff;

    font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 24px;
    line-height: 1.5;
    color: #000000;

    -webkit-font-smoothing: antialiased;
}
</style>
