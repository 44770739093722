import AuthService from "../services/auth.services";
import Error from "@/views/Error.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/plugins/i18n";
import store from "@/store";
import AuthDriverProvider from "@/views/AuthDriverProvider";
import GoToMainPage from '@/views/GoToMainPage.vue'

Vue.use(VueRouter);

let defaultRoutes = [
  {
    path: "",
    name: "Landing",
    component: () => {
        return import("@/views/Landing.vue");
    },
    meta: {
      layout: "landing-layout",
    },
  },
  {
    path: "/contacts",
    name: "LandingContact",
    component: () => {
      return import("@/views/LandingContact.vue");
    },
    meta: {
      layout: "landing-layout",
    },
  },
  {
    path: "/products",
    name: "LandingProducts",
    component: () => {
      return import("@/views/LandingProducts.vue");
    },
    meta: {
      layout: "landing-layout",
    },
  },
  {
    path: "/legacy",
    name: "LandingPrivacy",
    component: () => {
      return import("@/views/LandingPrivacy.vue");
    },
    meta: {
      layout: "landing-layout",
    },
  },
  {
    path: "/usecases",
    name: "LandingUseCases",
    component: () => {
      return import("@/views/LandingUseCases.vue");
    },
    meta: {
      layout: "landing-layout",
    },
  },
  {
    path: "/price",
    name: "LandingPricing",
    component: () => {
      return import("@/views/LandingPricing.vue");
    },
    meta: {
      layout: "landing-layout",
    },
  },
  {
    path: "agencies",
    name: "Agencies",
    component: () => {
      return import("@/views/Agencies.vue");
    },
    meta: {
      layout: "landing-layout",
    },
  },
  {
    path: "login",
    name: "login",
    component: () => {
      return import("@/views/PartnerLanding.vue");
    },
    meta: {
      layout: "landing-layout",
    },
  },
  {
    path: "registration",
    name: "registration",
    component: () => {
      return import("@/views/PartnerLanding.vue");
    },
    meta: {
      layout: "landing-layout",
    },
  },
  {
    path: "referals",
    name: "referals",
    component: () => {
      return import("@/views/Referals.vue");
    },
    meta: {
      layout: "default-layout",
    },
  },
  {
    path: "marketing",
    name: "marketing",
    component: () => {
      return import("@/views/Marketing.vue");
    },
    meta: {
      layout: "default-layout",
    },
  },
  {
    path: "token/:token",
    name: "ViewByUserToken",
    component: () => import("@/views/ViewByUserToken.vue"),
  },
  {
    path: "partners",
    name: "partners",
    component: () => import("@/views/Partners.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "partners/start",
    name: "startPartners",
    component: () => import("@/views/StartPartners.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "partners/users",
    name: "usersPartners",
    component: () => import("@/views/UsersPartners.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "partners/hotels",
    name: "hotelsPartners",
    component: () => import("@/views/HotelsPartners.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "partners/payments",
    name: "paymentsPartners",
    component: () => import("@/views/PaymentsPartners.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "partners/settings",
    name: "settingsPartners",
    component: () => import("@/views/SettingsPartners.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "product/update",
    name: "ProductUpdate",
    component: () => import("@/views/productUpdate.vue"),
  },
  {
    path: "dashboard",
    name: "dashboard",
    component: () => import("@/views/Dashboard.vue"),
    meta: {
      layout: "dashboard-layout",
      requiresAuth: true,
    },
  },
  {
    path: "statistics/marketing",
    name: "StatisticsMarketing",
    component: () => import("@/components/Constructor/Statistics/SegmentStatistics.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "statistics/main",
    name: "Visitors",
    component: () => import("@/components/Constructor/Statistics/MainFullStatistics.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "statistics/pwa",
    name: "pwa-statistics",
    component: () => import("@/components/Constructor/Statistics/PWAStatistics.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "statistics/order",
    name: "OrderStatistics",
    component: () => import("@/components/Constructor/Statistics/OrderStatistics"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "statistics/reviews",
    name: "Review",
    component: () => import("@/components/Constructor/Statistics/ReviewStatistics"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "statistics/banners",
    name: "BannerStatistics",
    component: () => import("@/components/Constructor/Statistics/BannerStatistics.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "statistics/quests",
    name: "QuestStatistics",
    component: () => import("@/components/Constructor/Statistics/QuestStatistics.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "quests",
    name: "quests",
    component: () => import("@/views/Quests.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "objects",
    name: "My property",
    component: () => import("@/views/Objects/Objects.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "rooms",
    name: "rooms",
    component: () => import("@/views/Rooms/Rooms.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "contact",
    name: "Contact",
    component: () => import("@/views/Contact.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "order",
    name: "order",
    component: () => import("@/views/Order.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "legal",
    name: "Legal",
    component: () => import("@/views/Legal.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "objects/create",
    name: "New property",
    component: () => import("@/views/Objects/ObjectsCreate.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "objects/clone/:id",
    name: "Clone property",
    component: () => import("@/views/Objects/ObjectsClone.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "objects/edit/:id",
    name: "ObjectsEdit",
    component: () => import("@/views/Objects/ObjectsEdit.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "objects/menu",
    name: "ObjectsSuccess",
    component: () => import("@/views/Objects/ObjectsSuccess.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "polls",
    name: "Reviews",
    component: () => import("@/views/Polls.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "billing",
    name: "billing",
    component: () => import("@/views/Billing.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path:"banners",
    name:"banners",
    component:() => import("@/views/Banners.vue"),
    meta:{
      layout: "default-layout",
      requiresAuth: true
    }
  },
  {
    path: "labels",
    name: "Labels",
    component: () => import("@/views/Labels.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "notifications",
    name: "notifications",
    component: () => import("@/views/Notifications.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "statistics/notifications",
    name: "StatisticsNotifications",
    component: () => import("@/components/Constructor/Statistics/NotificationStatistics.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
    {
        path: "stickers-constructor",
        name: "StickerConstructor",
        component: () => import("@/views/Stickers/StickerConstructor.vue"),
        meta: {
            layout: "default-layout",
            requiresAuth: true,
        },
    },
  {
    path: "stickers/create",
    name: "StickersCreate",
    component: () => import("@/views/Stickers/StickersCreate.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "welcome",
    name: "welcome",
    component: () => import("@/views/Welcome.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "settings",
    name: "Profile",
    component: () => import("@/views/Settings.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "help",
    name: "help",
    component: () => import("@/views/Help.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "events",
    name: "events",
    component: () => import("@/views/Events.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "constructor/:id",
    name: "Constructor",
    component: () => import("@/views/Constructor.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "mails/:id",
    name: "Mails",
    component: () => import("@/views/Mails.vue"),
    meta: {
      layout: "default-layout",
      requiresAuth: true,
    },
  },
  {
    path: "/:driver/callback",
    name: "callback",
    component: AuthDriverProvider,
    meta:{
      requiresAuth: false,
      layout: "empty-layout"
    }
  },
];

const routes = [
  {
    path: "/:lang([r|h][u|r])?",
    component: {
      render: (h) => h("router-view"),
    },
    children: defaultRoutes,
  },
  {
    path: "/*",
    component: GoToMainPage,
  },
  {
    path: "*",
    name: "error",
    component: Error,
    meta: {
      layout: "landing-layout",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
['push','replace'].forEach(method => {
  const originalMethod = router[method];
  router[method] = function m(location) {
    return originalMethod.call(this, location).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        // capture exception
        console.log(error);
      }
    })
  };
});
router.beforeEach((to, from, next) => {
  let checkRoom = to.path.split('/');
  if(checkRoom.length === 4){
      if(checkRoom[2] === '8104'||checkRoom[2] === '8105' || checkRoom[2] === '8108'){
          if(checkRoom[3] !== 'cart' && checkRoom[3] !== 'reviews' && checkRoom[3] !== 'favorite' && checkRoom[3] !== 'profile'){
            window.location.href = `https://guest.eco/${checkRoom[2]}?room=${checkRoom[3]}`
          }else{
            if(checkRoom[1] === 'm'){
              let url = ''
              for(let i = 1;i<checkRoom.length;i++){
                url += checkRoom[i]+'/'
              }
              window.location.href = `https://guest.eco/${url}`
            }
          }
      }else{
        if(checkRoom[1] === 'm' ||checkRoom[1] === 'google' ||checkRoom[1] === 'facebook'){
          let url = ''
          for(let i = 1;i<checkRoom.length;i++){
            url += checkRoom[i]+'/'
          }
          window.location.href = `https://guest.eco/${url}`
        }
      }
  }
  else{
    if(checkRoom[1] === 'm'){
      let url = ''
      for(let i = 1;i<checkRoom.length;i++){
        url += checkRoom[i]+'/'
      }
      window.location.href = `https://guest.eco/${url}`
    }
  }


  const admin_token = to.query.admin_token;
  const lang = to.params.lang || "en";
  const langs = ["ru", "en", "hr"];

  if (admin_token) {
    AuthService.postAdminLogin({ admin_token }).then((res) => {
      if (res.token) {
        store.commit("setToken", res.token);
        next("/welcome");
      }
    });
  }

  if (lang) {
    if (langs.includes(lang)) {
      if (i18n.locale !== lang) {
        i18n.locale = lang;
      }
    } else {
      i18n.locale = "en";
      next(false);
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuth) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
