import { VApp } from 'vuetify/lib/components/VApp';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{staticClass:"app app_admin"},[_c('div',{staticClass:"page"},[(_vm.$store.state.profile.name)?_c('div',{staticClass:"main",style:(("padding:" + (_vm.$route.name === 'Constructor'?'40px 0 20px':'40px 30px 20px') + ";margin-left:" + _vm.left + ";width:" + _vm.width + ";"))},[_c('div',{staticClass:"header__constructor",staticStyle:{"padding-right":"30px"}},[_c('span',{staticStyle:{"margin-right":"15px","font-weight":"bold"},attrs:{"id":"userName"}},[_vm._v(_vm._s(_vm.$store.state.profile.name))]),_c(VMenu,{attrs:{"open-on-hover":"","bottom":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,{staticClass:"avatar"},[_c('img',_vm._g(_vm._b({attrs:{"src":_vm.$store.state.profile.avatar ? ("" + (_vm.VUE_APP_STORAGE_URL+_vm.$store.state.profile.avatar)) : require('@/assets/icons/user_placeholder.svg'),"alt":"John"}},'img',attrs,false),on))])]}}],null,false,3137129504)},[_c(VList,[_c(VListItem,{staticClass:"menu__item"},[_c(VListItemTitle,{on:{"click":_vm.createproperty}},[_vm._v("Create property")])],1),_c(VListItem,{staticClass:"menu__item"},[_c(VListItemTitle,{on:{"click":_vm.listproperty}},[_vm._v("My Facilities")])],1),_c(VListItem,{staticClass:"menu__item"},[_c(VListItemTitle,{on:{"click":_vm.logout}},[_vm._v("Sign Out")])],1)],1)],1)],1),_vm._t("default"),(!_vm.$route.path.includes('constructor'))?_c('div',{staticClass:"footer",staticStyle:{"height":"70px","position":"relative"}},[_c('ul',[_c('li',{staticStyle:{"cursor":"default"}},[_vm._v("© 2023 Tourist, Inc.")]),_c('li',{on:{"click":function($event){return _vm.routeTo('Legal')}}},[_vm._v("Privacy & Legal")]),_c('li',{on:{"click":function($event){return _vm.routeTo('Contact')}}},[_vm._v("Contact")])])]):_vm._e()],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }